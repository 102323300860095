<template>
	<div>
		<Header style="margin-bottom: 20px;" :title="'累计充值金额：￥' + total" @back="onBack()"></Header>
		<a-spin :spinning="loading">
			<div>
				<a-table :pagination="false" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 1800 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'payTime'">
							{{ transDateTime(record.payTime) }}
						</template>
						<template v-if="column.key === 'chargePriceBalance'">
							{{ record.chargePriceBalance + record.prizePriceBalance }}
						</template>
						<template v-if="column.key === 'accountPrice'">
							{{ record.chargePriceBalance + record.prizePriceBalance + record.chargePrice + record.prizePrice }}
						</template>
						<template v-if="column.key === 'type'">
							<div v-if="record.type === 1">用户充值</div>
							<div v-if="record.type === 2">初始金额</div>
							<div v-if="record.type === 3">后台充值</div>
						</template>
						<template v-if="column.key === 'orderNo'">
							{{ record.orderNo || '-' }}
						</template>
						<template v-if="column.key === 'handleUserName'">
							{{ record.handleUserName || '-' }}
						</template>
						<template v-if="column.key === 'status'">
							{{ ['待支付', '已完成', '退款中', '已退款'][record.status] }}
						</template>
						<template v-if="column.key === 'refundInfo'">
							<div v-if="record.status === 3">
								<div>退款金额：￥{{ record.refundMessage?.refund || '-' }}</div>
								<div>售后时间：{{ transDateTime(record.auditTime) }}</div>
								<div>操作人：{{ record.refundMessage?.createUser || '-' }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.type !== 3 && record.status == 1" type="link" @click="onRefund(record)">{{ record.type == 2 ? '售后' : '售后' }}</a-button>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		
		<a-modal v-model:visible="showRefundModal" title="售后" width="750px" @ok="onRefundOk">
			<a-spin :spinning="loading">
				<a-form ref="refundFormRef" :model="modelRef" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16 }">
					<a-form-item label="退款金额" name="refundAmount" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.refundAmount" :min="0" :precision="2" placeholder="请输入"></a-input-number>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import {
		getUnionCardChargeOrderById
	} from "@/service/modules/report";
	import { userUnionCardChargeOrderRefund } from '@/service/modules/coupon.js';
	export default {
		props: {
			id: {
				type: Number,
				default: 0
			},
		},
		components: {
			Header
		},
		data() {
			return {
				total: 0,
				showModal: false,
				isEdit: false,
				isSee: false,
				loading: false,
				showAll: false,
				searchData: {},
				formState: {},
				columns: [{
					title: '充值时间',
					key: 'payTime',
				}, {
					title: '充值前剩余余额',
					key: 'chargePriceBalance'
				}, {
					title: '充值金额',
					dataIndex: 'chargePrice',
					width: 120
				}, {
					title: '赠送金额',
					dataIndex: 'prizePrice',
					width: 120
				}, 
				// {
				// 	title: '充值后金额',
				// 	key: 'accountPrice',
				// 	width: 120
				// }, 
				{
					title: '实付金额',
					dataIndex: 'payment',
					width: 120
				}, {
					title: '充值类型',
					key: 'type',
					width: 120
				}, {
					title: '订单号',
					key: 'orderNo'
				}, {
					title: '操作充值用户',
					key: 'handleUserName',
				}, {
					title: '状态',
					key: 'status',
					width: 120
				}, {
					title: '售后信息',
					key: 'refundInfo' //todo
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100,
					align: 'center'
				}],
				list: [],
				pagination: {
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showRefundModal: false,
				modelRef: {}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getUnionCardChargeOrderById({
						userCardId: this.id,
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data;
						this.total = 0;
						ret.data.forEach(item => {
							this.total += item.payment;
						})
						// this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onRefundOk() {
				this.$refs.refundFormRef.validate().then(()=> {
					this.$confirm({
						title: '提示',
						content: '确定售后退款吗？',
						onOk: async ()=> {
							this.loading = true;
							let ret = await userUnionCardChargeOrderRefund(this.modelRef);
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('售后成功！')
								this.showRefundModal = false;
								this.getData();
							}
						}
					})
				})
			},
			onRefund(item) {
				this.modelRef = {
					orderId: item.id
				}
				this.showRefundModal = true;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>